import React from 'react'
import Link from 'gatsby-link'
import Typist from 'react-typist';

import './home-info.css'

class HomeInfo extends React.Component {
  render() {
    return (
      <div className="home-container container">
        <h2 className="latest-info h3">
          <Typist 
          startDelay={2525}
          avgTypingDelay={57}
          cursor={{ }}
          >            
            I code elegant, engaging, and easy-to-use websites using the latest HTML, CSS, and JavaScript.&nbsp;
            <Typist.Backspace count={73} delay={6669} />
            I’m a front-end website developer who’s been crafting pixels since 2007.&nbsp;
            <Typist.Backspace count={95} delay={6669} />
            <Typist.Delay ms={50000} />
            Hey!&nbsp;<Typist.Delay ms={1500} />Why are you still here?&nbsp;
            <Typist.Backspace count={24} delay={3000} />
            Are you still here? :D&nbsp;
            <Typist.Backspace count={23} delay={30000} />
            <Typist.Backspace count={5} delay={500} />
          </Typist>
        </h2>
        <div className="home-logo">
          <h1 className="title">
            <Typist 
            startDelay={1000}
            avgTypingDelay={112}
            cursor={{
                blink: false,
                element: '_'
            }}>
              JEFFREY WOLFF
            </Typist>
          </h1>
        </div>
      </div>
    );
  }
}

export default HomeInfo
